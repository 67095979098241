import React, { useEffect, useRef, useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import {
  ALGOLIA_APP_ID,
  ALGOLIA_API_KEY,
  project,
  isQuickTaskProject,
  title,
  descriptionInfo,
} from "../../utils/constant.urls";
import algoliasearch from "algoliasearch";
import {useMatch} from "@reach/router";
import {Drawer} from "@mui/material";
import TaskContainer from "../../components/task-view/task-container";
import TaskCard from "../../components/task-components/task-card";
import {Task} from "../../models/task";
import {Theme} from "@mui/material/styles";
import {useTheme} from "@mui/system";
import Text from "../../components/data-display/text";
import {useMediaQuery} from "@mui/material";
import {navigate} from "gatsby";
import MapView from "../../components/TasksMap/map-view";
import LoadingTaskCard from "../../components/task-components/loading-task-card";
import BrowseTasksSearchBox from "../../components/browse-tasks/search-box";
import BrowseTaskersSearchBox from "../../components/browse-tasks/search-box-taskers";
import CircularProgressBar from "../../components/progress-bars/circular-progress-bar";
import {Router} from "@reach/router";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/index.reducers";
import {useLocation} from "@reach/router";
import {OpenRoute} from "../../components/app-routes/app-routes";
import Paper from "@mui/material/Paper";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import SEO from "../../components/seo/seo";
import {firestore} from "../../utils/firebase.utils";
import {UserData} from "../../models/user-data";
import TaskerCard from "../../components/tasker-card/tasker-card";
import {environment} from "../../environments/quickTaskEnvironemt";
const client = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_API_KEY);
const tasksIndex = client.initIndex("tasks");
const taskersIndex = client.initIndex("users");
const QuikTaskMainColor = environment.mainColor;
const BrowseTasks = (props) => {
  const theme = useTheme<any>();
  const styles = {
    container: {
      paddingTop: "24px",
    },
    drawerPaper: {
      transition: "0.3s",
      background: theme.palette.background.default,
      width: "calc(1280px/3)",
      left: "calc((100% - 1280px)/2)",
      height: "calc(100vh - 88px)",
      zIndex: 300,
      border: "none",
      top: "88px",
      [theme.breakpoints.down("md")]: {
        width: "calc(960px/(12/5))",
        left: "calc((100% - 960px)/2)",
      },
      [theme.breakpoints.down("sm")]: {
        width: "calc(100vw - 48px)",
        left: "24px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "calc(100vw - 24px)",
        left: "12px",
      },
    },
    rootTabContainer: {
      transform: "translateY(4px)",
      background: "white",
      padding: "0 24px",
      borderRadius: "6px",
    },
    rootTab: {
      textTransform: "none",
      padding: "0",
      minWidth: "unset",
      justifyContent: "flex-start !important",
      fontSize: theme.typography.pxToRem(15),
      marginRight: "48px",
    },
    indicatorTab: {
      display: "flex",
      height: "4px",
      justifyContent: "center",
      backgroundColor: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      "& > div": {
        width: "100%",
        backgroundColor: isQuickTaskProject ? QuikTaskMainColor : theme.palette.primary.main,
      },
    },
    filtersContainer: {
      // width: 'calc(1280px/3.1)',
      paddingBottom: "10px",

      zIndex: 1300,
      // left:  'calc((100% - 1280px)/2)',
    },
    drawerPaperHidden: {
      transition: "0.3s",
      zIndex: 300,
      background: theme.palette.background.default,
      width: "calc(1280px/3)",
      left: "calc((100% - 1280px)/2)",
      height: "calc(100vh - 88px)",
      border: "none",
      top: "88px",
      [theme.breakpoints.down("md")]: {
        width: "calc(960px/(12/5))",
        left: "calc((100% - 960px)/2)",
      },
      [theme.breakpoints.down("sm")]: {
        width: "calc(100vw - 48px)",
        left: "24px",
      },
      [theme.breakpoints.down("xs")]: {
        width: "calc(100vw - 24px)",
        left: "12px",
      },
      transform: "translateX(-120%)",
    },
  };
  const sentinel = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState("");
  const [queryUsers, setQueryusers] = useState("");
  const [tasks, setTasks] = useState<Task[]>([]);
  const [user, setUser] = useState<UserData[]>([]);
  const [assigned, setAssigned] = useState<Task[]>([]);
  const [completeTasks, setCompleteTasks] = useState<Task[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [clicked, setClicked] = useState(0);
  const [tasksType, setType] = React.useState("all");
  const [currentPage, setPage] = useState<number>(0);
  const {userData} = useSelector((state: RootState) => state.userData);
  const d = new Date();
  const minDate = new Date(d.setDate(d.getDate() - 14));

  const getCompleteTasks = async () => {
    let d = new Date();
    let minDate = new Date(d.setDate(d.getDate() - 14));
    let tasksRef = firestore.collection("tasks");
    let tasks: Task[] = [];
    const tasksList = await tasksRef
      .where("isComplete", "==", true)
      .where("dateToStart", ">", minDate)
      .where("project", "==", project)
      .limit(5)
      .orderBy("dateToStart")
      .get();
    for (let index = 0; index < tasksList.docs.length; index++) {
      let element = tasksList.docs[index].data();
      element.id = tasksList.docs[index].id;
      tasks.push(new Task(element));
    }
    setCompleteTasks(tasks);
  };
  const getAssignedTasks = async () => {
    let d = new Date();
    let minDate = new Date(d.setDate(d.getDate() - 14));
    let tasksRef = firestore.collection("tasks");
    let tasks: Task[] = [];
    const tasksList = await tasksRef
      .where("isAssigned", "==", true)
      .where("isDispute", "==", false)
      .where("isComplete", "==", false)
      .where("isCancelled", "==", false)
      .where("dateToStart", ">", minDate)
      .where("project", "==", project)
      .limit(5)
      .orderBy("dateToStart")
      .get();
    for (let index = 0; index < tasksList.docs.length; index++) {
      let element = tasksList.docs[index].data();
      element.id = tasksList.docs[index].id;
      tasks.push(new Task(element));
    }
    setAssigned(tasks);
  };
  const onSentinelIntersection = (entries: IntersectionObserverEntry[]) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        if (hasMore) {
          tasksIndex
            .search(query, {
              hitsPerPage: 10,
              page: currentPage + 1,
              filters: `dateToStartUnix > ${
                new Date().getTime() / 1000
              }  AND isAssigned:false AND isCancelled:false  AND project:${project}`,
            })
            .then(({hits, nbPages, page}) => {
              let t: Task[] = [...tasks];
              if (hits.length) {
                hits.forEach((h) => {
                  t.push(new Task(h));
                });
                const tasksToSet = t.filter(
                  (item) =>
                    !item.isDispute &&
                    !item.isCancelled &&
                    !item.isAssigned &&
                    !item.isExpired &&
                    !item.isComplete
                );
                setTasks(tasksToSet);
              }
              setPage(page);
              if (nbPages === page + 1) {
                setHasMore(false);
                getAssignedTasks();
                getCompleteTasks();
              } else {
                setHasMore(true);
              }
            });
        }
      }
    });
  };

  useEffect(() => {
    let observer = new IntersectionObserver(onSentinelIntersection);
    if (sentinel.current) observer.observe(sentinel.current);

    return function cleanup() {
      observer.disconnect();
    };
  });

  useEffect(() => {
    setHasMore(false);
    setTasks([]);
    setPage(0);
    tasksIndex
      .search(query, {
        hitsPerPage: 10,
        filters: `dateToStartUnix > ${
          new Date().getTime() / 1000
        } AND isAssigned:false AND isCancelled:false AND project:${project}`,
      })
      .then(({hits, nbPages, page}) => {
        let t: Task[] = [];
        if (hits.length) {
          hits.forEach((h) => {
            t.push(new Task(h));
          });

          const tasksToSet = t.filter(
            (item) =>
              !item.isDispute &&
              !item.isCancelled &&
              !item.isAssigned &&
              !item.isExpired &&
              !item.isComplete
          );
          setTasks(tasksToSet);
        }
        setPage(page);

        if (nbPages === page + 1) {
          setHasMore(false);
          getAssignedTasks();
          getCompleteTasks();
        } else setHasMore(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [query]);

  useEffect(() => {
    setHasMore(false);
    setUser([]);
    setPage(0);
    taskersIndex
      .search(queryUsers, {
        //hitsPerPage: 10,
        //page: currentPage + 1,
        filters: `project:"${project}"`,
      })
      .then(({hits, page}) => {
        let t: UserData[] = [];
        if (hits.length) {
          hits.forEach((h) => {
            t.push(new UserData(h));
          });

          setUser(t);
        }

        setPage(page);
      });
  }, [queryUsers]);

  useEffect(() => {
    // TODO LISTEN FOR CHANGES FROM ALGOLIA
  }, []);
  const task = () => {
    navigate("/tasks/");
  };

  const taskeres = () => {
    navigate("/tasks/taskers");
  };

  const taskeresN = (index) => {
    navigate(
      userData[index]?.uid === userData?.uid
        ? `/profile${userData[index]?.slug_title}`
        : `/profile${userData?.slug_title}`
    );
  };

  const handleChangeIndicator = (event, newValue) => {
    if (newValue === 0) {
      setType("all");
      task();
    } else if (newValue === 1) {
      setType("all");
      taskeres();
    }
    setClicked(newValue);
  };
  const mdUp = useMediaQuery(theme.breakpoints.up("md"));
  const xsDown = useMediaQuery(theme.breakpoints.down("xs"));

  const updateSearch = (q: string) => {
    setQuery(q);
  };
  const updateSearchtaskers = (q: string) => {
    setQueryusers(q);
  };
  const renderTabs = () => {
    return (
      <Tabs
        variant="scrollable"
        scrollButtons="auto"
        sx={{
          "& .MuiTabs-root": styles.rootTabContainer,
          "& .MuiTabs-indicator": styles.indicatorTab,
        }}
        TabIndicatorProps={{children: <div />}}
        value={clicked}
        onChange={handleChangeIndicator}
        aria-label="simple tabs example"
      >
        <Tab
          disableRipple
          sx={{
            "& .MuiTab-root": styles.rootTab,
          }}
          label={
            <Text variant={"body2"} bold>
              Tasks
            </Text>
          }
        />
        <Tab
          disableRipple
          sx={{
            "& .MuiTab-root": styles.rootTab,
          }}
          label={
            <Text variant={"body2"} bold>
              Taskers
            </Text>
          }
        />
      </Tabs>
    );
  };

  const taskMatch = useMatch("/tasks/:id/*");
  // const offerMatch = useMatch("/tasks/:id/offers")

  return (
    <>
      <SEO title={`${title}- ${descriptionInfo}`} pathname={"/tasks"} description={""} />
      <>
        <Container
          fixed={mdUp}
          style={{...(styles.container as React.CSSProperties), background: !mdUp ? "white" : ""}}
          maxWidth={"lg"}
        >
          <Drawer
            variant="permanent"
            PaperProps={{
              id: "browse-tasks",
              //style: {marginTop: "85px", marginLeft: "40px", zIndex: "0"},
            }}
            sx={{
              "& .MuiDrawer-paper": {
                ...(mdUp || (!mdUp && !taskMatch) ? styles.drawerPaper : styles.drawerPaperHidden),
              },
            }}
            //PaperProps={{
            //  id: "browse-tasks",
            //}}
            anchor="left"
            // style={{
            //     transform: (!mdUp && !props.match?.params["id"]) ? "" : "translateX(-100%)"
            // }}
          >
            <Text
              variant={"body2"}
              style={{
                marginLeft: "20px",
                marginTop: "10px",
                marginBottom: "10px",
                color: isQuickTaskProject ? QuikTaskMainColor : "rgb(78, 62, 253)",
              }}
            >
              I am looking for...
            </Text>

            <Paper
              square
              style={{
                marginLeft: "18px",
                width: "93%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {
                <div
                  style={{
                    ...(styles.filtersContainer as React.CSSProperties),
                    left: !xsDown ? "calc((100% - 1235px)/2)" : "",
                    width: xsDown ? "inherit" : "calc(1280px/3.4)",
                  }}
                >
                  {renderTabs()}
                </div>
              }
            </Paper>
            {clicked === 1 ? (
              <>
                <BrowseTaskersSearchBox onUpdate={updateSearchtaskers} />
                <div style={{background: "#F7F8FB"}}>
                  {queryUsers && (
                    <Text
                      variant={"body2"}
                      component={"p"}
                      style={{
                        color: "gray",
                        marginLeft: "19px",
                        marginTop: "30px",
                      }}
                      bold
                    >
                      {user.length} Taskers Found
                    </Text>
                  )}
                </div>

                <div
                  style={{
                    display: "inline-block",
                    width: "calc(100% - 36px)",
                    marginTop: queryUsers ? "8px" : "20px",
                    marginLeft: "18px",
                    zIndex: 300,
                  }}
                >
                  {user.length
                    ? user.map((user, index) => (
                        <>
                          <div
                            key={user.uid}
                            onClick={() => navigate(`/profile${user.slug_title}`)}
                          >
                            <TaskerCard user={user} index={index} key={user.uid} />
                            <div style={{height: "16px"}} />
                          </div>
                        </>
                      ))
                    : ""}
                </div>
              </>
            ) : (
              <>
                <BrowseTasksSearchBox onUpdate={updateSearch} />

                <div style={{background: "#F7F8FB"}}>
                  {query && (
                    <Text
                      variant={"body2"}
                      component={"p"}
                      style={{
                        color: "gray",
                        marginLeft: "19px",
                        marginTop: "60px",
                      }}
                      bold
                    >
                      {tasks.length} Tasks Found
                    </Text>
                  )}
                </div>

                <div
                  style={{
                    display: "inline-block",
                    width: "calc(100% - 36px)",
                    marginTop: query ? "8px" : "20px",
                    marginLeft: "18px",
                    zIndex: 300,
                  }}
                >
                  {tasks.length
                    ? tasks.map((task, index) => (
                        <>
                          <div key={task.id} onClick={() => navigate(`/tasks${task?.slug_title}`)}>
                            {task && (
                              <TaskCard task={task} selected={taskMatch?.id} key={task.id} />
                            )}{" "}
                            <div style={{height: "16px"}} />
                          </div>
                        </>
                      ))
                    : Array.from(new Array(9)).map((i, innerIndex) => (
                        <div key={" " + innerIndex}>
                          <LoadingTaskCard index={i} key={innerIndex} />
                          <div style={{height: "16px"}} />
                        </div>
                      ))}

                  {assigned?.length > 0 &&
                    assigned?.map((task, index) => (
                      <>
                        {task.isAssigned && !task.isDispute && !task.isCancelled && (
                          <div key={task.id} onClick={() => navigate(`/tasks${task?.slug_title}`)}>
                            {task && (
                              <TaskCard task={task} selected={taskMatch?.id} key={task.id} />
                            )}
                            <div style={{height: "16px"}} />
                          </div>
                        )}
                      </>
                    ))}
                  {completeTasks?.length > 0 &&
                    completeTasks?.map((task, index) => (
                      <>
                        {task.isComplete && (
                          <div key={task.id} onClick={() => navigate(`/tasks${task?.slug_title}`)}>
                            {task && (
                              <TaskCard task={task} selected={taskMatch?.id} key={task.id} />
                            )}
                            <div style={{height: "16px"}} />
                          </div>
                        )}
                      </>
                    ))}

                  <div ref={sentinel} />
                  {hasMore && (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "24px 0",
                      }}
                    >
                      <CircularProgressBar />
                    </div>
                  )}
                </div>
              </>
            )}
          </Drawer>
          {!xsDown && (
            <Grid
              container
              direction="row"
              justifyContent="flex-end"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                {clicked === 0 ? (
                  <Grid>
                    {taskMatch?.id ? (
                      <TaskContainer matchPath={"/tasks/:id/*"} currentPath={"tasks"} />
                    ) : (
                      // @ts-ignore
                      <MapView tasks={tasks} />
                    )}
                  </Grid>
                ) : (
                  // @ts-ignore
                  <MapView tasks={tasks} />
                )}
              </Grid>
            </Grid>
          )}
          {/*TODO Uncomment*/}
          {xsDown && (
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={12} sm={12} md={7} lg={8} xl={8}>
                {clicked === 0 ? (
                  <Grid>
                    {taskMatch?.id ? (
                      <TaskContainer matchPath={"/tasks/:id/*"} currentPath={"tasks"} />
                    ) : (
                      // @ts-ignore
                      <MapView tasks={tasks} />
                    )}
                  </Grid>
                ) : (
                  // @ts-ignore
                  <MapView tasks={tasks} />
                )}
              </Grid>
            </Grid>
          )}
        </Container>
      </>
    </>
  );
};

const App = () => {
  const location = useLocation();
  return (
    <div className="content">
      <Router location={location}>
        {/* Browse Tasks - No Task Selected */}
        <OpenRoute path={"/tasks/*"} component={BrowseTasks} />
      </Router>
    </div>
  );
};

export default App;