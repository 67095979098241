import React from 'react';
import { InfoWindow } from 'google-maps-react';
import { createRoot } from 'react-dom/client';

export default class InfoWindowEx extends React.Component {
  constructor(props) {
    super(props);
    this.infoWindowRef = React.createRef();
    this.contentElement = document.createElement('div');
    this.root = createRoot(this.contentElement);
  }

  componentDidUpdate(prevProps) {
    if (this.props.children !== prevProps.children) {
      this.root.render(React.Children.only(this.props.children));
      this.infoWindowRef.current.infowindow.setContent(this.contentElement);
    }
  }

  render() {
    return <InfoWindow ref={this.infoWindowRef} {...this.props} />;
  }
}
